import { cn } from "@/lib/utils"

export default function Checkbox({ className = '', ...props }) {
    return (
        <input
            {...props}
            type="checkbox"
            className={cn(
                'rounded border-cyan-600 text-cyan-600 shadow-sm focus:ring-cyan-500 ',
                className
            )}
        />
    );
}
